import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'details',
    loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'copertura',
    loadChildren: () => import('./copertura/copertura.module').then( m => m.CoperturaPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'costo-casa-calc',
    loadChildren: () => import('./calcolatori/costo-casa-calc/costo-casa-calc.module').then( m => m.CostoCasaCalcPageModule)
  },
  {
    path: 'costo-studi-calc',
    loadChildren: () => import('./calcolatori/costo-studi-calc/costo-studi-calc.module').then( m => m.CostoStudiCalcPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
